.list{
	padding-left: 0;
	padding-right: 0;
	border-radius: $border-radius-base;
	&.no-border{
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.list-item{
	display: block;
	position: relative;
	padding: 12px 16px;
	@include clearfix();
	&:last-child .list-body:after{
		border: none;
	}
	.no-border &{
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.no-padding &{
		padding-left: 0;
		padding-right: 0;
	}
}

.list-left{
	float: left;
	padding-right: 1rem;
	+ .list-body{
		margin-left: 56px;
	}
}

.list-body{
	h3{
		font-size: 16px;
		margin: 0 0 3px 0;
		font-weight: normal;
	}
	&:after{
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		border-bottom: 1px solid $border-color-lt;
	}
	.inset &:after{
		left: 72px;
	}
	.no-border &:after{
		display: none;
	}
}
