﻿.form-group {
    ak-upload {
        .k-upload {
            border-color:white;

            .k-upload-button {
                //margin-left:0;
            }
        }
    }
}