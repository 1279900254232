.navside {
	border: inherit;
	ul{
		padding-left: 0;
		padding-right: 0;
  		list-style: none;
	}
	[flex]{
		overflow: auto;
	}
	.nav {
		border: inherit;
		li {
			border: inherit;
			position: relative;
			li {
				a {
					line-height: $aside-nav-sub-height;
					padding-left: 3.5rem;
					.nav-text{
						padding: ($aside-nav-sub-height - 1.125)/2 0;
					}
				}
				li a{
					padding-left: 4.5rem;
				}
				li li a{
					padding-left: 5.5rem;
				}
			}
			a{
				@include clearfix();
				display: block;
				padding: 0 1rem;
				line-height: $aside-nav-height;
				position: relative;
				&:hover,
				&:focus{
					background-color: $min-black;
				}
			}
			&.active{
				background-color: $min-black;
				> a {
					background-color: transparent;
					.nav-caret i{
						@include rotate(180deg);
					}
				}
			}
		}
	}
	.nav-header{
		padding: 0 1rem;
		line-height: $aside-nav-height;
		&:hover,
		&:focus{
			background-color: transparent !important;
		}
		&:after{
			display: none;
		}
	}
	.navbar-brand{
		float: none;
		margin-right: 0;
	}
}

.nav-fold{
	padding: 0.75rem 1rem;
}

.nav-text{
	display: block;
	line-height: 1.125rem;
	padding: ($aside-nav-height - 1.125)/2 0;
	.nav > li > a & {
		font-weight: 500;
	}
	span,
	small{
		display: block;
	}

	.navside &{
		opacity: 0.75;
	}
	.navside a:hover > &,
	.navside a:focus > &,
	.navside .active > a > &{
		opacity: 1;
	}
	.navbar & {
		line-height: 1;
		vertical-align: middle;
		display: inline-block;
		padding: 0;
		span.text-xs{
			margin-top: 0.25rem;
		}
	}
}

.nav-icon {
	float: left;
	line-height: inherit;
	margin-right: 1rem;
	top: 0;
	min-width: 1.5rem;
	text-align: center;
	i{
		position: relative;
		svg,
		img{
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	.navbar & {
		margin-right: 0.5rem;
	}
}

.nav-label{
	float: right;
	margin-left: 1rem;
	font-style: normal;
	z-index: 1;
	
	.navbar & {
		margin-left: 0.5rem;
	}
}

.nav-caret{
	float: right;
	margin-left: 1rem;
	opacity: 0.45;
	i{
		@include transition(transform 0.3s ease-in-out);
	}

	.navbar & {
		margin-left: 0.5rem;
	}
}

.nav-sub{
  max-height: 0;
  overflow: hidden;
  @include transition(max-height 0.4s ease 0s);
  .active > &{
    max-height: 50rem;
  }
}
