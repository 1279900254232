.label{
  padding: .25em .5em;
  &.no-bg{
    background-color: transparent;
    color: inherit;
  }
  font-weight: bold; 
  background-color: $label-bg;
}

.label{
  &.up{
    position: relative;
    top: -10px;
    min-width: 4px;
    min-height: 4px;
    display: inline-block;
  }
}

.label-lg{
  font-size: 1.33rem;
  padding: 5px 9px;
}

.label-sm{
  padding: 1px 4px;
}

.label-xs{
  padding: 0px 3px;
}

.list-group-item.active > .label, 
.nav-pills > .active > a > .label{
  color: $dark-black;
}
