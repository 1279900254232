﻿modal {
    &#contacts {
        .modal-body {
            background-color: #f0f0f0;
        }
    }
}

.blur-dat {
    //-webkit-filter: blur(5px) grayscale(50%);
}

body.not-used {    
    ai-dialog-overlay {
        bottom: 0;
        left: 0;
        position: fixed;
        top: 0;
        right: 0;
        opacity: 0;
    }

    ai-dialog-overlay.active {
        opacity: 1;
        //z-index: 1031 !important;
        background-color: rgba(0,0,0,.5);
        //-webkit-filter: blur(5px) grayscale(50%);
    }

    ai-dialog-container {
        display: block;
        
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        overflow-x: hidden;
        overflow-y: auto;
        pointer-events: none;
        -webkit-overflow-scrolling: touch;
        outline: 0;
    }

    ai-dialog-container.active {
        opacity: 1;
        //z-index: 1032 !important;
    }

    ai-dialog {
        position: relative;
        display: block;
        min-width: 300px;
        box-shadow: inherit;
        border: 1px solid rgba(0,0,0,.2);
        border-color: rgba(120,130,140,.13);
        border-radius: .3rem !important;    
        padding: 0;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
        margin: auto;
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
        background: white;
        pointer-events: auto;
    }

    ai-dialog > ai-dialog-header {
        display: block;
        padding: 16px;
        border-bottom: 1px solid #e5e5e5;
    }

    ai-dialog > ai-dialog-header > button {
        float: right;
        border: none;
        background: none;
        font-size: 22px;
        position: relative;
        top: -4px;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    ai-dialog > ai-dialog-body {
        display: block;
        padding: 16px;
    }

    ai-dialog > ai-dialog-footer {
        display: block;
        padding: 6px;
        border-top: 1px solid #e5e5e5;
        text-align: right;
    }

    ai-dialog > ai-dialog-footer button {
        color: #333;
        background-color: #fff;
        padding: 6px 12px;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin: 5px 0 5px 5px;
    }

    ai-dialog > ai-dialog-footer button:disabled {
        cursor: default;
        opacity: 0.45;
    }

    ai-dialog > ai-dialog-footer button:hover:enabled {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }

    &.ai-dialog-open {
        overflow: hidden;
    }
}
