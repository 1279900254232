﻿broker-item-search {
    #broker-item-search-large {
        &.k-input,
        .k-textbox > input {
            height: 5.75rem;
            text-indent: 2.25rem;
            font-size: 1.8rem;
            color: #444;
            line-height: 1.33333;
            font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: normal;
        }

        @include media-breakpoint-down(sm) {
            &.k-input,
            .k-textbox > input {
                font-size: 1.2rem;
                text-indent: 1rem;
            }
        }

        @include media-breakpoint-down(xs) {
            &.k-input,
            .k-textbox > input {
                font-size: 1rem;
                //text-indent: 1rem;
            }
        }

        ~ .k-loading {
            bottom: 40px;
            right: 40px;
        }
    }
}

.k-list-container {
    border-color: #ebebeb;
    /*.k-popup>.k-group-header, .k-popup>.k-virtual-wrap>.k-group-header {
            background: none;
            color: #000;
            border-bottom:1px solid #eee;
        }

        .k-popup .k-list .k-item>.k-group, .k-popup>.k-group-header, .k-popup>.k-virtual-wrap>.k-group-header {
            text-transform: uppercase;
            font-size: 1.4rem;
            text-align:left;
        }*/
    .k-state-default .heading {
        font-size: 16px;
        padding-left: 10px;
    }

    ::-webkit-input-placeholder {
        font-weight: normal;
        color: #999;
        opacity: .5;
    }

    .k-state-default .heading {
        font-size: 1.3em;
        padding-left: 5px;
    }

    .k-state-default .heading small {
        font-size: 12px;
    }

    .k-state-default .heading .highlight {
        text-decoration: none;
        font-weight: 600;
    }

    .k-state-default .label {
        font-size: 1em;
    }

    .k-state-default .label.policies {
        background-color: #0cc2aa;
    }

    .k-state-default .label.insureds {
        background-color: #f44455;
    }

    .k-state-default .label.claims {
        background-color: #6887ff;
    }

    &.k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
        background: #f1f1f1;
        border-bottom: 1px solid #ccc;
    }

    &.k-popup .k-list .k-item > .k-group {
        color: #444;
    }
}

input[name="claimNumber"] {
    text-transform: uppercase !important;

    &::-webkit-input-placeholder { /* WebKit browsers */
        text-transform: none;
    }

    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        text-transform: none;
    }

    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        text-transform: none;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        text-transform: none !important;
    }
}

.form-control {
    color: #444 !important;
}

/*.k-autocomplete .k-input,
.k-textbox > input{
    height: 3.75rem;
    text-indent: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.33333;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.k-autocomplete .k-loading{
    bottom: 25px;
    right: 25px;
}

.k-popup>.k-group-header, .k-popup>.k-virtual-wrap>.k-group-header {
            background: none;
            color: #000;        
            border-bottom:1px solid #eee;
        }

        .k-popup .k-list .k-item>.k-group, .k-popup>.k-group-header, .k-popup>.k-virtual-wrap>.k-group-header {
            text-transform: uppercase;
            font-size: 1.4rem;
            text-align:left;
        }

.k-state-default .heading{
    font-size: 16px;
    padding-left: 10px;
}

::-webkit-input-placeholder{
    font-weight: normal;
    color: #999;
    opacity: .5;
}

.k-state-default .heading{
    font-size: 16px;
    padding-left: 5px;
}

.k-state-default .heading small{
    font-size: 12px;
}

.k-state-default .heading .highlight{
    color: #000000;
    font-weight: bold;
}

.k-state-default label.Policy{
  
}*/

